<script lang="ts" setup>
const isAnimation = ref(false);
const dailyWheel = ref<HTMLElement | null>(null);
const isMobile = useDevice();

onMounted(() => {
	const observer = new IntersectionObserver(
		(el) => {
			isAnimation.value = el[0].isIntersecting;
		},
		{ threshold: isMobile ? 0.1 : 0.5 }
	);
	if (dailyWheel.value) {
		observer.observe(dailyWheel.value);
	}
});
</script>
<template>
	<div ref="dailyWheel" data-tid="daily-wheel" class="box" :class="{ 'animation-active': isAnimation }">
		<NuxtImg class="box-wheel" src="/nuxt-img/daily-wheel/wheel.png" loading="lazy" format="avif" alt="wheel" />
		<div class="box-drum">
			<NuxtImg class="img-drum" src="/nuxt-img/daily-wheel/drum.png" loading="lazy" format="avif" alt="drum" />
		</div>
		<NuxtImg class="box-neon" src="/nuxt-img/daily-wheel/neon.png" loading="lazy" format="avif" alt="neon" />
		<div class="box-spin">
			<NuxtImg class="box-spin-default" src="/nuxt-img/daily-wheel/spin.png" loading="lazy" format="avif" alt="spin" />
			<NuxtImg
				class="box-spin-neon"
				src="/nuxt-img/daily-wheel/spin-neon.png"
				loading="lazy"
				format="avif"
				alt="spin-neon"
			/>
		</div>
		<div class="box-lamp">
			<NuxtImg
				v-for="index in 15"
				:key="index"
				src="/nuxt-img/daily-wheel/lamp.png"
				loading="lazy"
				format="webp"
				alt="lamp"
			/>
		</div>
	</div>
</template>

<style scoped lang="scss">
.box {
	position: relative;
	z-index: 1;
	display: inline-block;
	pointer-events: none;

	img {
		vertical-align: middle;
	}

	.box-wheel {
		width: 100%;
	}

	.box-drum {
		position: absolute;
		z-index: 1;
		left: 50%;
		top: 52%;
		transform: translate(-50%, -50%);
		width: 87%;

		.img-drum {
			width: 100%;
			height: 100%;
		}
	}

	.box-neon {
		position: absolute;
		z-index: 2;
		left: 50%;
		top: 8.2%;
		width: 20%;
		transform: translate(-50%, 0);
	}

	.box-spin {
		position: absolute;
		z-index: 3;
		width: 28.6193%;
		left: 50%;
		top: 51%;
		transform: translate(-50%, -50%);
		transform-origin: center;

		.box-spin-default {
			width: 100%;
			transform: scale(0.8105);
		}

		.box-spin-neon {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			width: 100%;
			opacity: 0;
			transform: scale(0.8105);
		}
	}

	.box-lamp {
		img {
			position: absolute;
			width: 6%;
			transform: translate(-50%, -50%);
			z-index: 4;

			&:nth-child(1) {
				top: 9.7%;
				left: 67.7%;
			}

			&:nth-child(2) {
				top: 19.8%;
				left: 83%;
			}

			&:nth-child(3) {
				top: 34%;
				left: 93%;
			}

			&:nth-child(4) {
				top: 51.7%;
				left: 96.6%;
			}

			&:nth-child(5) {
				top: 68.7%;
				left: 93%;
			}

			&:nth-child(6) {
				top: 83.7%;
				left: 83%;
			}

			&:nth-child(n + 7):nth-child(-n + 9) {
				display: none;
			}

			&:nth-child(10) {
				top: 83.7%;
				left: 17%;
			}

			&:nth-child(11) {
				top: 68.7%;
				left: 6.8%;
			}

			&:nth-child(12) {
				top: 51.7%;
				left: 3.5%;
			}

			&:nth-child(13) {
				top: 34%;
				left: 6.8%;
			}

			&:nth-child(14) {
				top: 19.8%;
				left: 17%;
			}

			&:nth-child(15) {
				top: 9.7%;
				left: 32%;
			}

			&:nth-child(2n) {
				opacity: 0;
			}
		}
	}

	&.animation-active {
		.img-drum {
			animation: animation-drum 16.5s infinite;
		}
		.box-spin-default {
			animation: animation-spin 16.5s infinite linear;
		}

		.box-spin-neon {
			animation: animation-spin-neon 16.5s infinite linear;
		}

		.box-lamp {
			img {
				&:nth-child(2n - 1) {
					animation: animation-lamp-odd 2s infinite linear;
				}

				&:nth-child(2n) {
					animation: animation-lamp-even 2s infinite linear;
				}
			}
		}
	}
}

@keyframes animation-drum {
	0%,
	100% {
		transform: rotate(0);
	}
	34% {
		transform: rotate(730deg);
	}
	39.3939%,
	99.9999% {
		transform: rotate(720deg);
	}
}

@keyframes animation-lamp-odd {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
}

@keyframes animation-lamp-even {
	0%,
	100% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}

@keyframes animation-spin {
	0%,
	10%,
	20%,
	30%,
	39.3939%,
	100% {
		transform: scale(0.8105);
	}
	5%,
	15%,
	25%,
	35% {
		transform: scale(1);
	}
}

@keyframes animation-spin-neon {
	0%,
	10%,
	20%,
	30%,
	39.3939%,
	100% {
		transform: scale(0.8105);
		opacity: 0;
	}
	5%,
	15%,
	25%,
	35% {
		transform: scale(1);
		opacity: 1;
	}
}
</style>
